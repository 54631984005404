import {
    Injectable,
    OnDestroy, Inject, PLATFORM_ID } from '@angular/core';  

import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';

import {
    concat,
    concatMap,
    concatWith,
    filter,
    forkJoin,
    map,
    mapTo,
    mergeMap,
    Observable,
    Subject,
    switchMap,
    takeUntil,
    tap,
    from,
    of ,
    timeout,
    catchError,
    Subscription
} from 'rxjs';
import {
    Globals
} from '../globals';
import {
    ConsentService
} from '../services/consent.service';
import {
    Data2Service
} from '../services/data2/data2.service';
import {
    HeadService
} from '../services/head.service';
import {
    JsonldserviceService
} from '../services/jsonldservice.service';

import { isPlatformBrowser } from '@angular/common';  

@Injectable({
    providedIn: 'root'
})
export class MasterGuard implements OnDestroy {

    msalGuardResponse = false;
    data2ServiceGuardResponse = false;
    subscriptions: Array < Subscription > = [];
    private readonly _destroying$ = new Subject < void > ();

    constructor(
        private router: Router,
        private data2Service: Data2Service,
        private route: ActivatedRoute,
        private globals: Globals,
        private consent: ConsentService,
        private head: HeadService,
        private jsonLDService: JsonldserviceService,
        private consentService: ConsentService,
        @Inject(PLATFORM_ID) private platformId: Object) {}

    public getCookie(name: string) {
        if (isPlatformBrowser(this.platformId)) {  
              // Safe to use document here  
              let ca: Array < string > = document.cookie.split(';');
              //console.log(document.cookie);
              let caLen: number = ca.length;
              let cookieName = `${name}=`;
              let c: string;
      
              for (let i: number = 0; i < caLen; i += 1) {
                  c = ca[i].replace(/^\s+/g, '');
                  if (c.indexOf(cookieName) == 0) {
                      return c.substring(cookieName.length, c.length);
                  }
              }
        }  
       
        return '';
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable < boolean | UrlTree > | Promise < boolean | UrlTree > | boolean | UrlTree {

        var h = new Headers();
        //     console.log("Headers:",h)
        var s = seo.find(x => state.url == x.route);


        if (s != null) {
            //console.log("Found matching SEO data:",state.url,s)
            this.head.setTitle(s.title);
            this.head.setDescription(s.description);
            this.head.setKeywords(s.keywords);
        } else {
            //console.warn("Missing matching SEO data for route:",state.url)
        }

        //console.log("MASTER GUARD > State.url > ",state.url)

        if (this.isWhitelisted(state.url)) {
            if (s != null) {
                this.jsonLDService.removeStructuredData();
                this.jsonLDService.insertSchema(this.jsonLDService.getPageSchema(s.title, "https://www.ifa.com" + s.route, s.description))
            }
            //        console.log(`Route '${state.url}' is whitelisted to be seen without consent.`);        
            //console.log("pagesThatDoNotContainHypotheticalBacktestedData.indexOf(state.url)>-1",pagesThatDoNotContainHypotheticalBacktestedData.indexOf(state.url)>-1, state.url)
            return true;
        } else {
            //console.log("MASTER AUTH GUARD > canActivateChild",state.url);
            return this.canActivate(childRoute, state);
        }
    }



    isWhitelisted(r: string) {
        return (pagesThatDoNotContainHypotheticalBacktestedData.indexOf(r) > -1 
            || r.indexOf("/quotes/") == 0
            || r.indexOf("/external") == 0
            || r.indexOf("/petitions/performance-index-vs-price-index") == 0
            );
    }



    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
        this.subscriptions.forEach(s => s.unsubscribe());
        this.jsonLDService.removeStructuredData();
    }

    getResolvedUrl(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/').replace("///", "/").replace("//", "/");
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable < boolean | UrlTree > | Promise < boolean | UrlTree > {

        //STATIC LIST OF WHITELISTED CONTENT
        if (this.isWhitelisted(state.url)) {
            return of(true);
        }

        //COOKIE BYPASS
        var cookie = this.getCookie("NET_TOKEN");
        if (cookie != "" && cookie != "UNKNOWN") {
            return of(true)
        }

        //RECORD OF CONSENTING ALREADY EXISTS
        var ls = localStorage.getItem("consents");
        if (ls != null) {
            var lsp = JSON.parse(ls)
            //      console.log("lsp",lsp)
            if (lsp[0].answer == "true") {
                return of(true);
            }
        }

        //CHECK THE DATA2 API FOR WHITELISTED ARTICLE OR VIDEO
        var regexAV = "/(articles|videos)/([a-z0-9A-Z_-]+)/?"
        var matchesAV = state.url.match(regexAV);
        if (matchesAV != null) {
            return this.data2Service.articleVideoWhitelistCheck$(matchesAV[2]).pipe(map((wl: boolean) => {
                if (wl) {
                    return true;
                } else {
                    this.routeElsewhere2(state);
                    return false;
                }
            }));
        } else {
            this.routeElsewhere2(state);
            return of(false);
        }
    }
    routeElsewhere2(state: RouterStateSnapshot) {
        localStorage.setItem("intercepted-route", JSON.stringify({
            url: state.url,
            date: new Date()
        }));
        var hasPreviousNavigation = Boolean(this.router.getCurrentNavigation()?.previousNavigation)
        //console.log("hasPreviousNavigation",hasPreviousNavigation)
        if (!hasPreviousNavigation) {
            var matches = pagesThatDoNotContainHypotheticalBacktestedData.filter(x => state.url.startsWith(x));
            //console.log("matches",matches)
            if (matches != null) {
                var longest = matches.reduce(
                    function(a, b) {
                        return a.length > b.length ? a : b;
                    }
                );
                //console.log("longest",longest);
                setTimeout(() => {
                    this.router.navigate([longest]);
                }, 1500)
            } else {
                this.router.navigate(['/']);
            }
            setTimeout(() => {
                //this.globals.signInModalEvents.emit(true); //MIKE A: 2023-02-10 As per Mark H we now just redirect to the consent page
                this.router.navigate(['/required-consent']);
            }, 2000)
            //console.log("in 2 seconds the globals.signInModalEvents will emit")
            //return false;
        } else {
            //this.globals.signInModalEvents.emit(true); //MIKE A: 2023-02-10 As per Mark H we now just redirect to the consent page
            this.router.navigate(['/required-consent']);
            //console.log("the globals.signInModalEvents will emit")
            //return false;
        }
    }



    canDeactivate(
        component: unknown,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState ? : RouterStateSnapshot): Observable < boolean | UrlTree > | Promise < boolean | UrlTree > | boolean | UrlTree {
        return true;
    }
    canLoad(
        route: Route,
        segments: UrlSegment[]): Observable < boolean | UrlTree > | Promise < boolean | UrlTree > | boolean | UrlTree {
        return true;
    }

}


export class IFAMeta {
    route = "";
    title = "";
    description = "";
    keywords = "";
    constructor(_route: string, _title: string, _keywords: string, _description: string) {
        this.route = _route;
        this.title = _title;
        this.keywords = _keywords;
        this.description = _description;
    }
}

//WHITELIST - ALL PAGES REQUIRE CONSENT EXCEPT THESE:
//ALPHABETICALLY LISTED
//NO PAGES BELOW SHOULD HAVE ANY HYPOTHETICAL BACKTESTED DATA / CHARTS / VIDEOS etc.
var pagesThatDoNotContainHypotheticalBacktestedData = [
    "/",
    "/404",
    "/about",
    "/about/advisors",
    "/about/client-services-specialists",
    "/about/investment-principles",
    "/about/management",
    "/about/our-fiduciary-role",
    "/about/value-of-ifa",
    "/academic-papers",
    "/app/gb/privacy-policy",
    "/app/privacy-policy",
    "/articles",
    "/become-client",
    "/book-library",
    "/calculators/401k-savings",
    "/calculators/net-income",
    "/calculators/net-worth",
    "/calculators/roth-ira",
    "/calculators/multiple-active-funds-calc",
    "/careers",
    "/college-savings",
    "/contact",
    // "/disclosures",
    // "/disclosures/additional-info",
    // "/disclosures/charts",
    // "/disclosures/continuity",
    // "/disclosures/history",
    // "/disclosures/index-data",
    // "/disclosures/risk",
    // "/disclosures/terms",
    "/demo-login",
    "/estate-planning",
    //"/eventregistration-venkat202309",
    //"/eventregistration-higgins202311",
    "/fee-calculator",
    "/fees",
    "/find-an-advisor",
    "/galtonboard",
    "/galtonboard/learn-more",
    "/galtonboard/proficiency-test",
    "/galtonboard/stem",
    "/galtonboard/videos",
    "/galtonboard/gallery",
    "/galtonboard/articles",
    "/galtonboard/probabilityexamplesinlife",
    "/galtonboard/contact-us",    
    "/glossary",
    "/gallery",
    "/gallery-all",
    "/institutional",
    "/login",
    "/network-members",
    "/network-members/sign-up",
    "/open-account",
    "/petition",
    "/petitions",
    "/petitions/performance-index-vs-price-index",
    "/portfolios",
    "/portfolios/ifa-index-portfolios",
    "/quotes",
    "/required-consent",
    "/retirement-plans",
    "/services",
    "/sustainability",
    "/search",
    "/store",
    "/tax-forms/1040",
    "/taxes",
    "/taxes/about",
    "/taxes/about/lisa-rimke",
    "/taxes/about/david-york",
    "/taxes/about/christine-mealy",
    "/taxes/about/kathleen-wetzel",
    "/taxes/faq",
    "/taxes/services",
    "/taxes/articles",
    "/taxes/videos",
    "/taxes/preparation",
    "/taxes/proposal",
    "/taxes/calculators",
    "/taxes/calculators/tax1040",
    "/taxes/calculators/charitabletaxsavings",
    "/taxes/calculators/estateplan",
    "/taxes/calculators/socialsecuritytaxable",
    "/taxes/calculators/retiredistrib",
    "/taxes/contact",
    "/taxes/login",
    "/videos",
    "/mark",
    "/wes",
    "/becky",
    "/denise",
    "/mary",
    "/derick",
    "/venkat",
    "/gordon",
    "/neal",
    "/daniel",
    "/tyler",
    "/kerrie",
    "/mark-higgins",
];

var seo = [
    new IFAMeta('/', 'Index Fund Advisors, Inc. - Fiduciary Wealth Services, Dimensional Funds', 'Index Funds, Financial Advisor', 'Index Fund Advisors is a fee-only independent fiduciary financial advisor that specializes in risk-appropriate portfolios of index funds.'),
    new IFAMeta('/about', 'About Us', 'About Index Fund Advisors, About IFA, wealth management firm, registered investment advisor', 'IFA is a fee-only advisory and wealth management firm, and a Registered Investment Adviser with the U.S. Securities and Exchange Commission.'),
    new IFAMeta('/about/advisors', 'Our Team - About Us', 'Index Fund Advisors Wealth Management Team, IFA Wealth Advisors, Financial Advisors', 'Comprised of knowledgeable, driven people who care about the relationships they build and the lives they help make financially secure.'),
    new IFAMeta('/about/client-services-specialists', 'Client Service Specialists - About Us', 'Index Fund Advisors Client Services Specialists, Financial Services Account Managers', 'Explore Index Fund Advisors\' Client Services Specialist. They will help you manage your account at Index Fund Advisors.'),
    new IFAMeta('/about/management', 'IFA Executives - About Us ', 'Index Fund Advisors Management, IFA Advisors, Investing, Financial Services', 'Explore Index Fund Advisors\' Management Team'),
    new IFAMeta('/about/value-of-ifa', 'Value of IFA - About Us', 'Value of IFA Wealth Advisor, long term wealth management strategies, risk-appropriate investment strategies', 'A knowledgeable advisor provides service beyond the investment, and value beyond what the market delivers.'),
    new IFAMeta('/about/investment-principles', 'Investment Principles - About Us', 'Index Fund Advisors Investment Principles, five chief investment principles, diversification, risk and return, financial markets efficient',
        'IFA bases its underlying investment philosophy on highly-esteemed Nobel Prize-winning academic research in the field of economic sciences.'),
    new IFAMeta('/about/our-fiduciary-role', 'Our Fiduciary Role - About Us', 'Index Fund Advisors Fiduciary Role, Working With Fiduciary, Fiduciary Retirement Plan Services', 'It\'s our legal duty to put your financial interests ahead of our own. IFA is committed to upholding fiduciary standards of care mandated by U.S. law.'),
    new IFAMeta('/academic papers', 'Academic Papers', 'Finance Industry Journals, White Papers, Academic Papers and Peer Reviews', 'Explore Index Fund Advisors\' Collection of Academic Papers On Investment Strategies and Market Research.'),
    new IFAMeta('/app', 'IFA\'s App for iPhones, iPads and Android Devices', 'Index Fund Advisors App, IFA App, Investment App, Investment Strategies App, Investment Education App, Investing Articles and Videos',
        'The Index Fund Advisors app can help you replace speculation with an education. Read about passive investment strategies from our articles or keep up to date by watching the latest videos.'),
    new IFAMeta('/articles', 'Articles', 'Investing Articles, Investing Education, Articles on Finance, Fund Comparison, fund vs benchmark', 'Search popular articles, videos & research from IFA\'s extensive archive.'),
    new IFAMeta('/awards', 'Awards ', 'Index Fund Advisor Awards, IFC Awards, Investing Awards, Top RIA Awards', 'Index Fund Advisors list of advisor awards from Barrons, CNBC, financial planning, investopedia and financial times.'),
    new IFAMeta('/become-client', 'Become an IFA Client', 'Become Index Fund Advisors Client, IFA Client, Trusted Partnerships, IFA Fiduciary', 'Become an Index Fund Advisors Client. IFA has a fiduciary duty to its clients. This means we are legally obligated to put our clients\' interests ahead of our own.'),
    new IFAMeta('/book-library', 'IFA Vintage and Current Book Library', 'Investing Books, Investing Education, Vintage Investing Books, Investing Library, Stock Market Research', 'Explore Index Fund Advisors\' Book Library. We own and collect books related to stock market research and events.'),
    new IFAMeta('/books', 'Index Funds: The 12-Step Recovery Program for Active Investors', 'index funds investing books, recovery program active investors, intelligent investing books, pitfalls of active investing, financial behaviors of investors',
        'This small, compact, 5x7, 318-page handbook on intelligent investing addresses the financial behaviors of investors, the pitfalls of active investing, and the benefits of passive investing with index funds.'),
    new IFAMeta('/calculator', 'IFA Index Calculator', 'IFA Index Calculator, Historical Index Return Calculator, ', 'Historical Return Calculator for Index Funds, Index Fund Portfolios. Monthly Returns and Annual Returns ranging from 1928 to present.'),
    new IFAMeta('/calculators/401k-savings', '401k Savings Calculator', '401k Savings Calculator, Retirement Plan Calculator, 401k contributions calculator, retirement savings calculator',
        'A 401(k) can be one of your best tools for creating a secure retirement. Use our 401k savings calculator to see what your potential retirement savings will look like.'),
    new IFAMeta('/calculators/multiple-active-funds-calc', 'Multiple Active Funds Calculator ', 'Multiple Active Funds Calculator, calculate active funds vs benchmark, ', 'Calculate Probability that of Active Fund Performance vs Benchmark'),
    new IFAMeta('/calculators/net-income', 'Net Income Calculator ', 'Home Budget Calculator, Net Income Calculator, Monthly Budget Calculator, Net Income Calculator', 'By entering your income and monthly expenditures, you can see how much you have left to save and where your money is being spent'),
    new IFAMeta('/calculators/net-worth', 'Net Worth Calculator', 'Net Worth Calculator, Calculate Your Networth, how much are you worth, ', 'This calculator helps you determine your net worth and estimates how it could grow (or shrink) over the next ten years.'),
    new IFAMeta('/calculators/roth-ira', 'Roth IRA Calculator', 'Roth IRA Calculator, Calculate Roth IRA, ', 'This calculator compares two alternatives with equal out of pocket costs to estimate the change in total net-worth, at retirement, if you convert your Traditional IRA into a Roth IRA.'),
    new IFAMeta('/careers', 'Careers', 'Index Fund Advisors Careers, Index Fund Advisors Jobs, IFA Jobs, Investing Jobs, Jobs in Finance, Wealth Management Careers, Fiduciary Opportunities',
        'Looking for a career in finance and investing? We are looking for passionate, motivated individuals to guide investors through all life and retirement stages. '),
    new IFAMeta('/charts', 'Chart Library', 'risk vs return charts, distribution charts, investment research charts, investment chart comparisons, Investing Charts, Investing Data', 'Explore Index Fund Advisors\' extensive chart library utilizing over 94 years of backtested data.'),
    new IFAMeta('/college-savings', 'College Savings Plan Analyzer', 'Save for College, College Savings Plan Analyzer, College Savings Calculator, how to save for college', 'IFA\'s College Savings Analyzer is a useful tool to help determine how much you will need to put your child through college.'),
    new IFAMeta('/contact', 'Contact Us', 'Contact Index Fund Advisors, IFA Contact, Index Fund Advisors Contact Information',
        'IFA offers customized investment management and portfolio strategies alongside tax planning and accounting, online financial planning and referral services for a better overall client experience. Contact us to learn more.'),
    new IFAMeta('/disclosures', 'Disclosures: Back-tested Performance', 'Index Fund Advisors Disclosures, Index Fund Advisors Hypothetical Backtested Performance Models', 'Disclosures for the Hypothetical Back-tested Performance of Model Index Portfolios and Indexes'),
    new IFAMeta('/disclosures/additional-info', 'Disclosures: Additional Information', 'Additional IFA Disclosures', 'Other Disclosures and Information IFA Considers to be Helpful to Potential Clients'),
    new IFAMeta('/disclosures/charts', 'Disclosures: Supplemental Charts', 'Index Fund Advisors Supplemental Disclosures, IFA Supplemental Disclosures, IFA Supplemental Chart Disclosures', 'A List of Supplemental Charts and Disclosures of IFA and DFA Indexes and Funds'),
    new IFAMeta('/disclosures/continuity', 'Disclosures: Continuity', 'Index Fund Advisors Business Continuity Plan', 'Index Fund Advisors, Inc. (IFA) Business Continuity Plan Statement'),
    new IFAMeta('/disclosures/history', 'Disclosures: History of Changes', 'Index Fund Advisors History of Changes, Changes to IFA Indexes, Changes to IFA Index Portfolios', 'History of Changes to IFA Indexes and IFA Index Portfolios'),
    new IFAMeta('/disclosures/index-data', 'Disclosures: Index Data Sources', 'Index Fund Advisors Index Descriptions, IFA Index Descriptions, ', 'The following descriptions, definitions and important information explain how IFA Indexes are constructed to simulate similar risk and return characteristics back to 1928.'),
    new IFAMeta('/disclosures/risk', 'Disclosures: Risk', 'Index Fund Advisors Risk Disclosures, IFA Risk Disclosures', 'Index Fund Advisors Risk Disclosures'),
    new IFAMeta('/disclosures/terms', 'Disclosures: Terms of Use', 'Index Fund Advisors Terms of Use, IFA Terms of Use', 'Index Fund Advisors Terms of Use'),
    new IFAMeta('/estate-planning', 'Estate Planning Attorneys', 'Estate Planning Attorneys, Estate Planning Law Firms', 'Explore estate planning attorneys and firms.'),
    new IFAMeta('/fee-calculator', 'Fee Calculator', 'Index Fund Advisors fee calculator, IFA fee calculator, ifa advisory fee, index fund advisors advisory fee', 'Calculate the advisory fee of Dimensional Fund Advisors (DFA), Index Fund Advisors, Inc. (IFA) in Taxable and Tax-Deferred Accounts.'),
    new IFAMeta('/fees', 'Fees ', 'Index Fund Advisor Fees, fee-only registered investment advisor, IFA fees, IFA investment fees', 'Index Fund Advisors, Inc. (IFA) is a fee-only Registered Investment Adviser. Our account minimum is $100,000.'),
    new IFAMeta('/gallery', 'IFA Gallery', 'Index Fund Advisors Art Gallery, Art of Investing, Art of Financial Science', 'Explore the art of financal science with IFA\'s art gallery.'),
    new IFAMeta('/gallery-all', 'IFA Full Gallery', 'Index Fund Advisors Art Gallery, Index Fund Advisors Paintings, IFA Art Gallery, IFA Paintings, Lala Ragimov, Investing Paintings',
        'IFA has a love for the arts, and we have commissioned several paintings by distinguished Russian artist, Lala Ragimov to tell the story of IFA, index funds and the pitfalls that accompany active investing.'),
    new IFAMeta('/glossary', 'Glossary of Terms', 'Index Fund Advisors Glossary, IFA Glossary, Common Investing Terms,', 'This page contains a list of technical terms related to investing that the users may come across on the website. '),
    new IFAMeta('/institutional', 'Institution Investing', 'Institution Investing, Institutional Investing, Institutional Brochure, Institutional Services', 'Download our Institutional Brochure or contact us to learn more about our Institutional Services'),
    new IFAMeta('/investingtheevidence', 'Investing: The Evidence', 'Investing Documentary Film, Investing the Evidence, IFA Film, IFA Documentary, Index Fund Advisors Film, Index Fund Advisors Documentary',
    'Host Robin Powell presents the documentary Investing: The Evidence where he discusses the evidence on how to invest properly.'),
    new IFAMeta('/erisa-338-fiduciary-advisors-and-services', 'ERISA Section 3(38) Fiduciary Investment Advisor', 'ERISA, Section 3(38), Fiduciary, Investment Manager, Investment Advisor',
        'IFA acts as a 3(38) investment manager, which mandates that we act in a fiduciary capacity as required by the Employee Retirement Income Security Act (ERISA).'),
    new IFAMeta('/fiduciary-investments-solutions-advisors-and-management-services', 'Fiduciary Investments Advisor & Management Services', 'Registered Investment Advisor, Fiduciary, Investment Advisor, Management Services',
        'As a Registered Investment Adviser, Index Fund Advisors (IFA) is required by law to act as a fiduciary and only make investment recommendations that are in the best interest of our clients. '),
    new IFAMeta('/financial-wealth-services-engineers', 'Fiduciary Investments Advisor & Management Services', 'Fiduciary Investments Advisor, Management Services, passive investment philosophy, investment academic research',
        'Our passive investment philosophy is based on decades of peer-reviewed academic research and empirical analyses.'),
    new IFAMeta('/notice-of-incident', 'Notice of Security Incident', 'Notice of Incident', 'Index Fund Advisors, Incorporated ("IFA") is providing notice of a data incident that may affect the security of certain individuals\' information.'),
    new IFAMeta('/links', 'Quick Links and Domains', 'Links, Resources', 'Explore various quick links and domains.'),
    new IFAMeta('/login', 'Login', 'Charles Schwab Login, IFA Report Access Login, TD Ameritrade login, Fidelity Login, eMoney Login', 'Index Fund Advisors client login includes access to IFA Report Access, eMoney, Schwab, TD Ameritrade and Fidelity accounts.'),
    new IFAMeta('/network-members', 'Promoters', 'Promoters, Advisors, Financial Services, Investing', 'The IFA Network Members are a select group of financial advisors who are authorized to use the up-to-date information and tools throughout IFA\'s web site.'),
    new IFAMeta('/network-members/sign-up', 'Promoters - Sign Up', 'Network, Members, Advisors, Financial Services, Investing', 'Index Fund Advisors Network Membership Program'),
    new IFAMeta('/open-account', 'Open New Account', 'Open a Brokerage, Traditional IRA, Roth IRA, Trust Account, Retirement Accounts, Taxes and Accounting', 'Open a Brokerage, Traditional IRA, Roth IRA, or Trust Account with Index Fund Advisors, Inc.'),
    new IFAMeta('/plates', 'Vintage Plates', 'Vintage, Plates, Art', 'Explore vintage investing plates'),
    new IFAMeta('/portfolios', 'IFA Index Portfolios Overview', 'IFA Index Portfolios, value tilted portfolios, Index Funds, Investing portfolios, mutual fund portfolios', 'The optimal investment is a globally diversified, tax-managed, and small and value tilted, mix of index funds matched to your unique risk capacity.'),
    new IFAMeta('/portfolios/ifa-index-portfolios', 'IFA Index Portfolios', 'IFA Index Portfolios, diversified portfolios, portfolios risk capacity', '100 Portfolios individualized, diversified and appropriately allocated according to your unique risk capacity.'),
    new IFAMeta('/portfolios/risk-and-return', 'Risk and Return', 'maximize expected returns, risk return, investing risk level', 'IFA offers a sophisticated approach to maximize expected returns at a given level of risk.'),
    new IFAMeta('/portfolios/matching-people-with-portfolios', 'Matching People with Portfolios', 'portfolio matching, IFA portfolios, risk capacity portfolios, risk exposure', 'To achieve optimal results, investors need to match their Risk Capacity with a specific risk exposure aligned to one of our 100 Portfolios.'),
    new IFAMeta('/quotes', 'Investing Quotes', 'popular Investing Quotes, warren buffet quotes, john bogle quotes, eugene fama quotes, charles schwab quotes', 'Popular quotes from notable investors and finance professionals such as warren buffett, john bogle, eugene fama, paul samuelson, charles schwab and many more.'),
    new IFAMeta('/required-consent', 'Required Consent', 'Required Consent', 'This section of IFA.com contains hypothetical back-tested performance data, which involves certain RISKS, LIMITATIONS and ASSUMPTIONS. Please read about this data below and click the CONFIRM button for access.'),
    new IFAMeta('/retirement-plans', 'Retirement Plan Solutions', 'Retirement Plans Solutions, Retirement Plans, 401k Plans, 403b plans, 457 plans, defined benefit contributions',
        'Customized 401(k), 403(b), 457, and Defined Benefit retirement plan solutions constructed to support the specific needs and objectives of owners/plan sponsors - while helping employees define a clear path for a successful financial future.'),
    new IFAMeta('/retirement-plans/fiduciary-partner', 'Fiduciary Partner', 'Fiduciary Partners, 3(38) investment fiduciary, passively managed index funds',
        'A business owner assumes a number of duties, along with personal liability, when overseeing a retirement plan. Fortunately, IFA acts as a 3(38) investment fiduciary, accepting liability and responsibility for the selection, monitoring and replacement of passively managed index funds.'),
    new IFAMeta('/retirement-plans/investments', 'Investments', 'Investment Strategies, low cost investment strategies, tax efficient investment strategies, diversified investment strategies', 'Low-cost, diversified, tax-efficient investment strategies supported by personalized, experienced advice and service.'),
    new IFAMeta('/retirement-plans/plan-participants', 'Plan Participants', 'Plan participants, retirement plan investment solutions, efficient retirement plans', 'IFA is dedicated to providing easy-to-understand and efficient retirement plan investment solutions that assist employees in securing their long-term financial success.'),
    new IFAMeta('/retirement-plans/plan-sponsors', 'Plan Sponsors', 'Retirement Plan Sponsors, Plan Sponsor Services, Plan Sponsor Support', 'Plan Sponsor services are designed to guide you, alleviate the administrative burden and provide ongoing support, monitoring, governance and communication to help ensure plan success.'),
    new IFAMeta('/retirement-plans/proposal', 'Request Fee Proposal', 'Comprehensive fee proposal, request fee proposal, 401k fees, fee proposal',
        'An IFA 401(k) offers a fully transparent suite of services for a reasonable fee. How does an IFA 401(k) stack up for your business? Let IFA show you a comprehensive preliminary fee proposal based on just a few simple questions.'),
    new IFAMeta('/retirement-plans/retirement-tools', 'Retirement Tools', 'Retirement Tools, Retirement Plan Scorecard, Retirement Analyzer, Risk Capacity Survey, 401k Savings Calculator',
        'We have a suite of retirement tools at your disposal such as our retirement plan scorecard, retirement analyzer, risk capacity survey, and many more.'),
    new IFAMeta('/services', 'Fiduciary Wealth Services', 'customized investment management, portfolio strategies, tax planning, accounting, financial planning, referral services',
        'IFA offers customized investment management and portfolio strategies alongside tax planning and accounting, online financial planning and referral services for a better overall client experience. An experienced IFA Wealth Advisor provides personalized advice to help clients achieve their long-term financial goals.'),
    new IFAMeta('/sitemap', 'Sitemap', 'Index Fund Advisors Sitemap, IFA Sitemap', 'Index Fund Advisors Sitemap'),
    new IFAMeta('/sustainability', 'Sustainable Portfolios', 'Sustainable Portfolios, Environmental Portfolios, Sustainability, Sustainable Funds, ',
        'IFA Sustainable portfolios provide investors an opportunity to achieve their long-term investment goals through a broadly diversified strategy focused on higher expected returns while addressing environmental issues and concerns.'),
    new IFAMeta('/videos', 'Videos ', 'Investing Videos, Investing Strategies, Investing Education', 'Educational investing videos & research from Index Fund Advisors\' covering a variety of investing related topics.'),
    new IFAMeta('/home page', 'Index Fund Advisors', 'Index Fund Advisors, Investing, Financial Services, Index Funds, Investors, Investment Advisors, Mutual Funds', 'Index Fund Advisors is a fee-only independent fiduciary financial advisor that specializes in risk-appropriate portfolios of index funds.'),
    new IFAMeta('/whats_new', 'What\'s New', 'Investing, Financial Services, Wealth Management, Investments, Index Funds, Investors, Advisors', 'With hundreds of videos and articles, IFA is dedicated to providing you with timely, data-driven information on the value of investing in index funds.'),
    new IFAMeta('/taxes', 'Taxes', 'Tax Returns, Tax Planning, Tax Advice, Bookkeeping, Accounting, Tax Proposal', 'IFA Taxes specializes in tax returns, tax advice and tax planning for individuals, businesses and estates.'),
    new IFAMeta('/survey', 'Risk Capacity Survey', 'risk capacity survey, risk tolerance questionnaire, risk tolerance survey, index funds',
        'Learning your Risk Capacity is the first step to maximizing your expected returns for the risks you take. Answer just a few basic questions and you will learn which portfolio captures the right mix of stocks and bonds that is best suited to you so you can effectively grow your money.'),
    new IFAMeta('/search', 'Search IFA', 'Search Index Fund Advisors ', 'Search the Index Fund Advisors website'),
    new IFAMeta('/retirement-analyzer', 'Retirement Plan Analyzer', 'Retirement Plan Analyzer, Retirement Tools, Retirement Services, Retirement Plans, 401k Plans', 'IFA\'s Retirement Plan Analyzer is a useful tool for helping investors get on track and stay there—leading them to a better retirement.'),
    new IFAMeta('/indexfundsthemovie', 'Index Funds: The Movie', 'Index Funds Movie, 12 step recovery program, Index Funds Documentary, Investing Education',
        'Index Funds: The 12-Step Recovery Program for Active Investors is a documentary based on the book that is changing the way the world invests by replacing speculation with an education'),
    new IFAMeta('/calculators/retirement-income', 'IFA\'s Retirement Income Calculator', 'Index Fund Advisors Monte Carlo, IFA Monte Carlo, Monte Carlo Simulation, Retirement Program Simulator',
        'View simulations using IFA\'s Monte Carlo Simulator Engine to explore possible distributions of retirement income. View sample simulation variability through retirement. Select from a wide variety of IFA Index Portfolios.'),

    new IFAMeta('/galtonboard', 'Galton Board Desktop Probability Machine', 'francis galton, galton board, the galton board, galton board for sale, investments, index funds, indexing, statistics, normal distribution, stocks, stock market, growth, visualize markets, bell curve, financial economics', 'TWO NEW 12 INCH TALL GALTON BOARDS WITH PASCAL’S TRIANGLE ARE AVAILABLE! They are probability demonstrators that illustrate randomness, the normal distribution, the binomial distribution, the central limit theorem, regression to the mean and single outcomes with one larger golden bead. Each board has an overlay of Pascal’s Triangle which shows the number of different paths that can be taken to get to each hexagon or pin.'),
    new IFAMeta('/galtonboard/proficiency-test', 'Galton Board Proficiency Test', 'Galton Board Proficiency Test', 'Galton Board Proficiency Test'),
    new IFAMeta('/galtonboard/learn-more', 'Learn More About Galton Board', 'galton board, pascals triangle, probability theory, randomness, normal distribution, fibonacci numbers, central limit theorem, binomial theory', "Learn More about Francis Galton, Pascal's Triangle, mathematical exhibits, mathematical thinkers, and mathematics."),
    new IFAMeta('/galtonboard/stem', 'Galton Board STEM Approved', 'stem certified, stem approved, stem approved product, science technology engineering and math, stem curriculum, STEM', 'Galton Board is a stem approved and certified product and falls under stem approved curriculum'),
    new IFAMeta('/galtonboard/videos', 'Videos Featuring the Desktop Galton Board', 'galton board videos, pascals triangle videos, grand illusions videos, vsauce, D!ing', 'Videos on desktop Galton Board, Francis Galton, physics, probability and the central limit theory.'),
    new IFAMeta('/galtonboard/gallery', 'Galton Board Gallery', 'francis galton, ibm, galton board, galton board photos, mathematica exhibit', "Various photos of Francis Galton's Galton Board including images from the very first New York World's Fair."),
    new IFAMeta('/galtonboard/articles', 'Articles on Probability, Standard Deviation and Randomness in the Stock Market', 'galton board articles, galton board probability articles, galton board stock market articles, galton board randomness articles', "Articles on Probability, Standard Deviation and Randomness in the Stock Market"),
    new IFAMeta('/galtonboard/probabilityexamplesinlife', 'Probability Examples In Daily Life', 'examples normal distribution, normal distribution examples, probability examples, bell curve examples', "Examples of normal distribtuion, probability and bell curves in everday examples of life."),
    new IFAMeta('/galtonboard/contact-us', 'Contact', 'contact ifa.com, contact galton board', "Contact ifa.com, makers of the Galton Board Desktop Probability Machine or get a quote."),

    
]